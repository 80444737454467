const DISBURSEMENT          = "alba/disbursements";
const DISBURSEMENT_FIND     = "alba/disbursements/find";
const DISBURSEMENT_PICK_UP  = "alba/disbursements/pick-up/:id";
const DISBURSEMENT_VALIDATE = "alba/disbursements/validate/:id";
const DISBURSEMENT_RETRX    = "alba/disbursements/retrx";
const OVERBOOKING           = "alba/overbookings";
const TAX                   = "alba/tax-payments";
const TAX_FIND              = "alba/tax-payments/find";
const TAX_RETRX             = "alba/tax-payments/repay";
const SALARY_CUT            = "alba/salary-cut-payments";
const SALARY_CUT_FIND       = "alba/salary-cut-payments/find";
const SALARY_CUT_RETRX      = "alba/salary-cut-payments/repay";
const GOVERNMENT_ENDPOINT   = "alba/governments";

export default {
    DISBURSEMENT,
    DISBURSEMENT_FIND,
    DISBURSEMENT_PICK_UP,
    DISBURSEMENT_VALIDATE,
    DISBURSEMENT_RETRX,
    OVERBOOKING,
    TAX,
    TAX_FIND,
    TAX_RETRX,
    SALARY_CUT,
    SALARY_CUT_FIND,
    SALARY_CUT_RETRX,
    GOVERNMENT_ENDPOINT
};
